import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_RESULT_ERROR, API_RESULT_SUCCESS, ApplicationNotificationService, GlobalConfiguration, NotificationMessage, NotificationMessageTypes } from '../../shared';


@Injectable({
    providedIn: 'root'
})
export class ServiceCallHandler {

    constructor(public config: GlobalConfiguration,
        public http: HttpClient,
        public messageService: ApplicationNotificationService) { }


    public handleResponseWithMessage(serviceCallEmitter: Observable<any>,
        successMessage: NotificationMessage,
        errorMessage: NotificationMessage,
        callback: Function,
        unknownEventMessage: NotificationMessage = {
            title: 'Unexpected error',
            messages: 'There was an unexpected error while processing your request, an incident has been sent to the system manager',
            type: NotificationMessageTypes.SystemErrorMessage
        }) {
        serviceCallEmitter.subscribe((result) => {

            if (result?.status == undefined) {
                // Do Nothing
            } else if (result.status == API_RESULT_SUCCESS) {
                this.messageService.sendMessage(successMessage);
            } else if (result.status == API_RESULT_ERROR) {
                this.messageService.sendMessage(errorMessage);
            } else {
                this.messageService.sendMessage(unknownEventMessage);
            }

            callback(result);
        });
    }

}