import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'limitText',
    standalone: true,
    pure: false
})
export class LimitTextPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(content: string | undefined, length: number = 40) {
        if (content == undefined || content == "") return content;

        if (content.length <= length) {
            return content;
        }

        return content.substring(0, length) + " ...";
    }
}
