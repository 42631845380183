// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { VERSION } from './version';

export const environment = {
  production: false,
  apiConfig: {
    host: 'demo.mapps.cc',
    port: 443,
    basePath: '/api',
    useSSL: true,
    resourcePath: '/assets',
    imagesPath: '/assets/images',
    serviceSignature: 'SHA-256'
  },
  support: {
    email: "support@clovercloudsolutions.com",
    subject: "Error in Service"
  },
  licenses: {
    tinyMCE: 'qvjsv0vfphl2ztdgamzvgvk9bqdf36ukqmflbwz6tzl122eb',
    googleMaps: 'AIzaSyDW6RCIj-BUKe5EFdoxYSLWJ_TPgl-0XgI'
  },
  uiConfig : {
    default_country : 'US',
    default_latitud: 25.6802816,
    default_longitud: -100.4404736,
    map_id: '33b574cb181f4de7',
    pageSize: 10,
    pageSizes: [10, 20, 50, 100],
    site_title: 'Mobius-Mitchell Energy Inc.',
    site_logo: 'logo.png',
    site_logo_large: 'logo_header_large.png',
    default_avatar: 'default_avatar.svg',
    anonymousUser: {
      id: 0,
      is_active: false,
      username: "anonymous",
      email: "",
      first_name: "Anonymous",
      last_name: "User",
      full_name: "Anonymous User",
      avatar: 'default_avatar.svg',
      role: {
        id: 0,
        name: "anonymous",
        description: "",
        default_url: "/",
        role_type: "anonymous"
      },
      groups: []
    }
  },
  i18n : {
    defaultCountry : 'US',
    countryCodes : ['US', 'MX', 'CA']
  },
  system : {
    version : VERSION,
    environment: 'demo'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
