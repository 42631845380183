import { Injectable, Optional } from '@angular/core';
import { Session } from './session.model';

@Injectable({
  providedIn: 'root'
})
export class Comparators {

  public compareIds(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
  public compareCodes(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.code === c2.code : c1 === c2;
  }
  public compareKeys(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.key === c2.key : c1 === c2;
  }

  public compareValue(c1: any, c2: any): boolean {
    return c1 == c2;
  };

}
