import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfiguration } from '../../shared';
import { Session } from './session.model';

const SESSION_DATA = 'session_data';

@Injectable({
   providedIn: 'root'
})
export class SessionService {

   constructor(public config: GlobalConfiguration, public http: HttpClient) { }

   getSession(): Session | null {      
      if ( sessionStorage.getItem(SESSION_DATA) == null ) {
         return null;
      }
      let sessionEntry: string = (sessionStorage.getItem(SESSION_DATA) != null? sessionStorage.getItem(SESSION_DATA) as string : "{}");
      let session = JSON.parse( sessionEntry ) as Session;

      const properties = JSON.stringify({"role": session.properties?.role, "permissions": session.properties?.permissions})

      return session;
   }

   updateSession( additionalAttributes: any ) {
      let session = this.getSession();
      if( session != undefined) {
         let sessionProperties = { ...session.properties, ...additionalAttributes };
         session.properties = sessionProperties;
         this.saveSession(session);
      }
   }

   saveSession( sessionInformation: Session ): void {
      sessionStorage.setItem(SESSION_DATA, JSON.stringify(sessionInformation));
   }

   invalidateSession(): void {
      sessionStorage.removeItem(SESSION_DATA);
   }

}