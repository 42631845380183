import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfiguration } from '../../shared';


@Injectable({
   providedIn: 'root'
})
export class LocationService {

   constructor(public config: GlobalConfiguration, public http: HttpClient) { }



}

