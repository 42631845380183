import { Injectable } from '@angular/core';

import moment from 'moment';

@Injectable({
   providedIn: 'root'
})
export class DateUtils {

    
  padDigits(number: number, digits: number) {
    return Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number;
  }

  getTime(date: Date, format_as_24hrs: boolean = false) {

    let hour = date.getHours();
    let minute = date.getMinutes();

    let formattedTime = this.padDigits(hour, 2) + ":" + this.padDigits(minute, 2);
    if ( format_as_24hrs) {
        formattedTime = this.padDigits(hour, 2) + ":" + this.padDigits(minute, 2);
    } else {
        let sufix = "AM";
        if ( hour > 12 ) {
            sufix = "PM";
            hour = hour - 12;
        }
        formattedTime = `${hour}:${minute} ${sufix}`;
    }

    return formattedTime;
  }

  combineDateTime(date: Date | undefined | null, time: string) {
    if (date == undefined || date == null) {
        date = new Date();
    }

    time = time.toLowerCase();
    let hour = 0;
    let minute = 0;

    if (time.indexOf("a") > 0 || time.indexOf("p") > 0) {
      if (time.indexOf("a") > 0) {
        hour = parseInt(time.split(":")[0]);
      } else {
        hour = 12 + parseInt(time.split(":")[0]);
      }
      minute = parseInt(time.split(":")[1]);
    } else {
      hour = parseInt(time.split(":")[0]);
      minute = parseInt(time.split(":")[1]);
    }

    let formattedTime = this.padDigits(hour, 2) + ":" + this.padDigits(minute, 2);

    const d = moment(date).format('YYYY-MM-DD');
    let isoTimestamp = `${d}T${formattedTime}`;
    const final_start_date = moment(isoTimestamp).toDate();
    return final_start_date;
  }
    
}
 