import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, pipe, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TokenService } from '../../service/security';

@Pipe({
    name: 'secureImageBase64',
    standalone: true,
    pure: false
})
export class SecureImageFromBase64Pipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) { }

    transform(url: string | ArrayBuffer | null | undefined) {
        if (url != null && url != undefined && !(url instanceof ArrayBuffer)) {
            return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        } else {
            return url;
        }
    }
}