export interface NotificationMessageType {
   type: string,
   icon: string
}

export interface NotificationMessage {
   title?: string
   messages: string | Array<string>;
   type: NotificationMessageType;
   timeout?: number;
   payload?: any;
}

export class NotificationMessageTypes {
   static InformationMessage: NotificationMessageType = { icon: 'icofont-info-circle', type: 'info' };
   static SuccessMessage: NotificationMessageType = { icon: 'icofont-tick-mark', type: 'success' };
   static ErrorMessage: NotificationMessageType = { icon: 'icofont-error', type: 'danger' };
   static WarningMessage: NotificationMessageType = { icon: 'icofont-warning', type: 'warning' };
   static NormalMessage: NotificationMessageType = { icon: 'icofont-like', type: 'light' };
   static SystemErrorMessage: NotificationMessageType = { icon: 'icofont-bomb', type: 'danger' };
}
