import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_RESULT_SUCCESS, BasicApiResponse, CrudApiResponse, FilterEntry, GlobalConfiguration, PageablePayload, Session, SessionService } from '../../shared';
import { Group, IsAuthenticated, JWTToken, OAuth2Auhtorization, Permission, Role, User, UserPermissions } from '../security';

import { jwtDecode } from 'jwt-decode';
import { map } from 'rxjs/operators';
import { TokenService } from './token.service';

@Injectable({
   providedIn: 'root'
})
export class SecurityService {

   public static REQUEST_PASSWORD_RESET = "PASSWORD_RESET";

   constructor(public config: GlobalConfiguration, public http: HttpClient, public tokenService: TokenService, public sessionService: SessionService) { }

   public authenticate(username: string, password: string): Observable<OAuth2Auhtorization> {
      this.tokenService.removeTokens();

      return this.http.post<OAuth2Auhtorization>(this.config.getAPIUrl('/oauth2/token'), { "username": username, "password": password, "grant_type" : "password", "scope":"security" })
         .pipe(map((res: OAuth2Auhtorization) => {
            this.tokenService.saveTokens(res.access_token, res.refresh_token, res.jwt)

            let jwt: JWTToken = jwtDecode(res.jwt);

            let session: Session = {
               key: jwt.jti,
               hash: res.hash || ""
            }

            this.sessionService.saveSession(session);

            return res;
         }));
   }

   public requestPasswordChange( username: string ): Observable<Boolean> {
      return this.http.post<BasicApiResponse>( this.config.getAPIUrl( '/security/user/'+username+'/password/reset'),{})
      .pipe(map((result) => {
         return result.status == "success";
      }));
   }

   public passwordChange( securityCode: string, username: string , password: string ) : Observable<Boolean> {
      return this.http.post<BasicApiResponse>( this.config.getAPIUrl( '/security/password/'+securityCode+'/'+username), { "password": password } )
      .pipe(map((result) => {
         return result.status == "success";
      }));
   }

   public validateSecurityRequest(hash: string, username: string, requestType: string) : Observable<Boolean> {
      return this.http.get<BasicApiResponse>( this.config.getAPIUrl('/security/password/' + hash + '/' + username + '/validate/' + requestType) )
         .pipe(map((result: BasicApiResponse)=> {
            return result.status == API_RESULT_SUCCESS;
         }));
   }

   public isAuthenticated(): Observable<boolean> {
      return this.http.get<CrudApiResponse<IsAuthenticated>>(this.config.getAPIUrl('/security/authenticated'))
         .pipe(map((res: CrudApiResponse<IsAuthenticated>) => {
            return res.entity.is_authenticated;
         }));
   }

   public logout(): Observable<BasicApiResponse> {
      return this.http.get<BasicApiResponse>(this.config.getAPIUrl('/security/logout'))
         .pipe(map((res: BasicApiResponse) => {
            this.tokenService.removeTokens()
            this.sessionService.invalidateSession();
            return res;
         }));
   }

   public getCurrentUser(): Observable<CrudApiResponse<User>> {
      return this.http.get<CrudApiResponse<User>>(this.config.getAPIUrl('/security/user'))
         .pipe((result) => {
            return result;
         });
   }


   public getAvailableRoles(role_types: string[] | null = null): Observable<PageablePayload<Role>> {
      return this.http.get<PageablePayload<Role>>(this.config.getAPIUrl('/security/roles' + (role_types != null ? '?types=' + role_types.join(',') : '')))
         .pipe((result) => {
            return result;
         });

   }

   public getAvailablePermissions(): Observable<PageablePayload<Permission>> {
      return this.http.get<PageablePayload<Permission>>(this.config.getAPIUrl('/security/permissions'))
         .pipe((result) => {
            return result;
         });
   }

}

