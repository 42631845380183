import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { SessionService } from '../services/session.service';

@Directive({
    selector: '[HasPermission]',
    standalone: true
})
export class SecurityGuardDirective {

    @Input('HasPermission') set permission(permission: string) {

        const session = this.sessionService.getSession();
        if (session?.properties?.permissions.includes(permission)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }

    constructor(private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<any>,
        private sessionService: SessionService) {
    }
}