import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Pipe({
    name: 'imageSource',
    pure: false,
    standalone: true
})
export class ImageSourcePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(image_src: string) {
        if (image_src) {
            return environment.apiConfig.imagesPath + '/' + image_src;
        }
        return '/assets/images/null.png';
    }
}
