import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { NotificationMessage } from './application-notifications.model';


@Injectable({ providedIn: 'root' })
export class ApplicationNotificationService {
    private subject = new Subject<NotificationMessage|null>();

    constructor(private translateService: TranslateService) {}

    sendMessage(message: NotificationMessage) {
        if ( message.title == undefined && !(typeof message.messages === undefined)) {
            message.title = 'Error while executing your request';
            if( message.payload != undefined ) { 
                message.messages = this.getMessageTransation(message.payload.error.messages);
            } else {
                message.messages = this.getMessageTransation(message.messages);
            }
        }
        this.subject.next(message);
    }

    clearMessages() {
        this.subject.next(null);
    }

    onMessage(): Observable<NotificationMessage|null> {
        return this.subject.asObservable();
    }

    getMessageTransation(messages: string | Array<string>) {
        if (typeof messages === "string") {
            return this.translateService.instant(messages);
        }

        let ret = '';
        messages.forEach((m)=>{
            ret = ret + this.translateService.instant(m) + '<br />';
        });
        return ret;
    }
}