import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalConfiguration } from '../../shared';

const ACCESS_TOKEN = 'access_token';
const REFRESH_TOKEN = 'refresh_token';
const JWT_TOKEN = 'jwt_token';

@Injectable({
   providedIn: 'root'
})
export class TokenService {

   constructor(public config: GlobalConfiguration, public http: HttpClient) { }

   getToken(): string | null{
      return sessionStorage.getItem(ACCESS_TOKEN);
   }

   getRefreshToken(): string | null {
      return sessionStorage.getItem(REFRESH_TOKEN);
   }

   getJwtToken(): string | null {
      return sessionStorage.getItem(JWT_TOKEN);
   }

   saveTokens( token:string, refreshToken:string, jwt:string): void {
      sessionStorage.setItem(ACCESS_TOKEN, token);
      sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
      sessionStorage.setItem(JWT_TOKEN, jwt);
   }

   removeTokens(): void {
      sessionStorage.removeItem(ACCESS_TOKEN);
      sessionStorage.removeItem(REFRESH_TOKEN);
      sessionStorage.removeItem(JWT_TOKEN);
   }

}