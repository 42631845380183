import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConversionService {

    decimalInchesToFeetAndInches(num: number): string {
        const feet = Math.floor(num / 12);
        const inches = Math.floor(num % 12);
        const fraction = num % 1;
        let fractionStr = '';

        if (fraction > 0) {
            const gcd = this._getGcd(Math.round(fraction * 16), 16);
            fractionStr = ` ${Math.round(fraction * 16 / gcd)}/${16 / gcd}"`;
        }

        return `${feet}'-${inches}"${fractionStr}`;
    }

    _getGcd(a: number, b: number): number {
        if (b === 0) return a;
        return this._getGcd(b, a % b);
    }

    feetAndInchesToDecimalInches(inputStr: string): number {

        if ( inputStr == undefined || parseInt(inputStr) == 0) {
            return 0;
        }

        let parts = inputStr.split('-');
        let totalInches: number = 0;
        let inches = 0;
        let fraction = 0;
        let tempfraction = '0';

        if (parts.length >= 1) {
            let temp =  parts[0];
            if ( temp.indexOf('\'') > 0 ){
                totalInches = parseFloat(temp) * 12;
            }
        }

        if ( parts.length >= 2) {
            let temp = parts[1];
            temp = temp.replace("\"","");
            let slices = temp;
            
            if ( temp.indexOf(' ') > 0) {
                let slices = temp.split(' ');
            }

            if ( slices.length > 1) {
                inches = parseFloat(parts[0]);
                tempfraction = slices[1].replace("\"","");
            } else {
                inches = 0;
                tempfraction = slices[0].replace("\"","");
            }

            if ( tempfraction.indexOf('/') > 0) {
                let slices = tempfraction.split('/');
                if (slices.length == 1) {
                    fraction = parseFloat(parts[0]);
                } else if (slices.length > 1 ){
                    if ( parseFloat(slices[1]) != 0) {
                        fraction = parseFloat(slices[0]) / parseFloat(slices[1]);
                    } else {
                        fraction = parseFloat(slices[0]);
                    }
                }
            } else {
                inches = inches + parseFloat(tempfraction);
            }
        }

        return totalInches + inches + fraction;
    }

    numericToFormattedNumber(num: number) : string {
        return num.toLocaleString('en-us', {minimumFractionDigits: 2})       
    }

    formattedNumberToNumeric(formatted: string) : number {
        if ( formatted == '0') return 0;
        return parseFloat(formatted.replace(/,/g, ''));
    }
}