import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, Observable, of, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { ADD, CrudApiResponse, FilterEntry, FIND_ALL, GlobalConfiguration, PageablePayload, PageablePayloadDefault, SEARCH, UPDATE } from "../shared";
import { Project } from "./operations";


export interface SearchableOptionsInterface<T> {
    items$: Observable<T[]>;
    loading: boolean;
}

export class SearchableOptions<T> implements SearchableOptionsInterface<T> {
    items$!: Observable<T[]>;
    loading: boolean = false;

    constructor( loadingFunction: (this: void) => Observable<T[]> ) {
        this.items$ = loadingFunction();
    }
}


export interface SearchableDataSource<T> {
    search<T>(term: string, search_limit: number): Observable<PageablePayload<T>>;    
    search<T>(term: string, search_limit: number, propertyName: string, propertyValue: string): Observable<PageablePayload<T>>;
}

export class BaseSearchableDataSource<T> implements SearchableDataSource<T> {

    constructor(
        public config: GlobalConfiguration,
        public http: HttpClient,
        public typeName: string) {
    }

    search<T>(term: string, search_limit: number): Observable<PageablePayload<T>>;
    search<T>(term: string, search_limit: number, propertyName: string, propertyValue: string): Observable<PageablePayload<T>>;
    search<T>(term: unknown, search_limit: unknown, propertyName?: unknown, propertyValue?: unknown): Observable<PageablePayload<T>> | Observable<PageablePayload<T>> {

        let searchUrl = this.config.getAPIUrlForType<T>(this.typeName, SEARCH);

        if ( propertyName != undefined && propertyValue != undefined) {
            searchUrl = searchUrl.replace(`:${propertyName}`, propertyValue as string);
        }
        if ( search_limit == undefined || (search_limit as number) < 0) {
            search_limit = 5;
        }

        return this.http.get<PageablePayload<T>>( searchUrl, {
                params: new HttpParams().set('limit', search_limit as number).set('query', term as string)                 
            })
            .pipe((result) => {
                return result;
            });
    }    
}

export interface DataServiceProvider<T> {

    findAll<T>(): Observable<PageablePayload<T>>;
    findAll<T>(filters: FilterEntry[]): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[]): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[], return_all: boolean): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[], return_all: boolean, exclude_fields: string[]): Observable<PageablePayload<T>>;

    getByProperty<T>(propertyName: string, propertyValue: any): Observable<CrudApiResponse<T>>;
    add<T>(entry: T): Observable<CrudApiResponse<T>>;
    update<T>(propertyName: string, propertyValue: any, entry: T): Observable<CrudApiResponse<T>>;
    remove<T>(propertyName: string, propertyValue: any): Observable<CrudApiResponse<T>>;
}

export class ArrayDataSourceProvider<T> implements DataServiceProvider<T> {

    private _data: Array<T> = [];

    constructor(data: Array<T>){
        this._data = data;
    }

    updateData(data: Array<T>) {
        this._data = data;
    }

    updateItem(idx: number, entry: T) {
        this._data[idx] = entry;
    }

    getData() {
        return this._data;
    }

    removeAtIndex(idx: number) {
        this._data.splice(idx, 1);
    }

    findAll<T>(): Observable<PageablePayload<T>>;
    findAll<T>(filters: FilterEntry[]): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[]): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[], return_all: boolean): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[], return_all: boolean, exclude_fields: string[]): Observable<PageablePayload<T>>;
    findAll(page?: unknown, page_size?: unknown, filters?: unknown, return_all?: unknown, exclude_fields?: unknown): Observable<PageablePayload<T>> | Observable<PageablePayload<T>> | Observable<PageablePayload<T>> | Observable<PageablePayload<T>> | Observable<PageablePayload<T>> | Observable<PageablePayload<T>> {
        return of(new PageablePayloadDefault<T>(this._data));
    }
    getByProperty<T>(propertyName: string, propertyValue: any): Observable<CrudApiResponse<T>> {
        throw new Error("Method not implemented.");
    }
    add<T>(entry: T): Observable<CrudApiResponse<T>> {
        throw new Error("Method not implemented.");
    }
    update<T>(propertyName: string, propertyValue: any, entry: T): Observable<CrudApiResponse<T>> {
        throw new Error("Method not implemented.");
    }
    remove<T>(propertyName: string, propertyValue: any): Observable<CrudApiResponse<T>> {
        throw new Error("Method not implemented.");
    }
    
}

export class BaseDataServiceProvider<T> implements DataServiceProvider<T> {

    constructor(public config: GlobalConfiguration,
        public http: HttpClient,
        public typeName: string) {
    }

    findAll<T>(): Observable<PageablePayload<T>>;
    findAll<T>(filters: FilterEntry[]): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[]): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[], return_all: boolean): Observable<PageablePayload<T>>;
    findAll<T>(page: number, page_size: number, filters: FilterEntry[], return_all: boolean, exclude_fields: string[]): Observable<PageablePayload<T>>;

    findAll(page?: unknown, page_size?: unknown, filters?: unknown, return_all?: unknown, exclude_fields?: unknown): Observable<PageablePayload<T>> | Observable<PageablePayload<T>> | Observable<PageablePayload<T>> | Observable<PageablePayload<T>> | Observable<PageablePayload<T>> {

        let _filters: FilterEntry[] = [];
        let _page: number = 1;
        let _page_size: number = environment.uiConfig.pageSize;
        let _return_all: boolean = false;
        let _exclude_fields: string[] = [];

        /// Fix case when only filters are called
        if ( page instanceof Array ) {
            filters = page;
            page = 1;
        }

        if (filters != undefined) _filters = filters as FilterEntry[];
        if (page != undefined) _page = page as number;
        if (page_size != undefined) _page_size = page_size as number;
        if (return_all != undefined) _return_all = return_all as boolean;
        if (exclude_fields != undefined) _exclude_fields = exclude_fields as string[];


        return this.http.get<PageablePayload<T>>(
            this.config.getAPIUrlForType<T>(this.typeName, FIND_ALL),
            {
                params: new HttpParams().set('current_page', _page)
                    .set('page_size', _page_size)
                    .set('filters', _filters.map((filter) => `${filter.property}|${filter.value}`).join(','))
                    .set('exclude', _exclude_fields.map((field) => field).join(','))
                    .set('return_all', _return_all)
            })
            .pipe((result) => {
                return result;
            });
    }
    getByProperty<T>(propertyName: string, propertyValue: any): Observable<CrudApiResponse<T>> {
        return this.http.get<CrudApiResponse<T>>( this.config.getAPIUrlForType<T>(this.typeName, UPDATE).replace(`:${propertyName}`, propertyValue))
        .pipe(map((result: CrudApiResponse<T>) => {
            return result;
        }));
    }
    add<T>(entry: T): Observable<CrudApiResponse<T>> {
        return this.http.post<CrudApiResponse<T>>(this.config.getAPIUrlForType<T>(this.typeName, ADD), entry)
        .pipe(map((result: CrudApiResponse<T>) => {
            return result;
        }));
    }
    update<T>(propertyName: string, propertyValue: any, entry: T): Observable<CrudApiResponse<T>> {
        return this.http.patch<CrudApiResponse<T>>( this.config.getAPIUrlForType<T>(this.typeName, UPDATE).replace(`:${propertyName}`, propertyValue) , entry)
        .pipe(map((result: CrudApiResponse<T>) => {
            return result;
        }));
    }
    remove<T>(propertyName: string, propertyValue: any): Observable<CrudApiResponse<T>> {
        return this.http.delete<CrudApiResponse<T>>( this.config.getAPIUrlForType<T>(this.typeName, UPDATE).replace(`:${propertyName}`, propertyValue))
        .pipe(map((result: CrudApiResponse<T>) => {
            return result;
        }));
    }


}
