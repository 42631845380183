import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AvailablePermissions {
    constructor() { }


    public AssetManagment = {
        Asset: {
            CanAccess: "asset_managment_asset_access",
            CanAdd: "asset_managment_asset_add",
            CanDecommission: "asset_managment_asset_decommission",
            CanDelete: "asset_managment_asset_delete",
            CanMove: "asset_managment_asset_move_to_depot",
            CanPrint: "asset_managment_asset_print_labels",
            CanUpdate: "asset_managment_asset_update",
            CanView: "asset_managment_asset_view",
        },
        AssetLend: {
            CanAccess: "asset_managment_asset_lend_access",
            CanLend: "asset_managment_asset_lend_asset",
            CanReturn: "asset_managment_asset_lend_return",
            CanView: "asset_managment_asset_lend_view",
            CanMakeAvailable: "asset_managment_asset_make_available",
        },
        AssetManagment: {
            CanAccess: "asset_managment_access",
        },
        Depot: {
            CanAccess: "asset_managment_depot_access",
            CanAdd: "asset_managment_depot_add",
            CanDelete: "asset_managment_depot_delete",
            CanUpdate: "asset_managment_depot_update",
            CanView: "asset_managment_depot_view",
        },
        Maintenance: {
            CanAccess: "asset_managment_maintenance_access",
            CanAdd: "asset_managment_maintenance_schedule_add",
            CanView: "asset_managment_maintenance_schedule_view",
        },
        Supplier: {
            CanAccess: "asset_managment_supplier_access",
            CanAdd: "asset_managment_supplier_add",
            CanDelete: "asset_managment_supplier_delete",
            CanExport: "asset_managment_supplier_export",
            CanImport: "asset_managment_supplier_import",
            CanUpdate: "asset_managment_supplier_update",
            CanView: "asset_managment_supplier_view",
        },
    }
    public Catalogs = {
        Attribute: {
            CanAccess: "catalogs_access",
        },
        Delivery: {
            CanAccess: "catalogs_delivery_access",
            CanAdd: "catalogs_delivery_add",
            CanDelete: "catalogs_delivery_delete",
            CanExport: "catalogs_delivery_export",
            CanImport: "catalogs_delivery_import",
            CanUpdate: "catalogs_delivery_update",
            CanView: "catalogs_delivery_view",
        },
    }
    public CustomerRelationshipManagment = {
        Contact: {
            CanAccess: "crm_contact_access",
            CanAdd: "crm_contact_add",
            CanDelete: "crm_contact_delete",
            CanExport: "crm_contact_export",
            CanImport: "crm_contact_import",
            CanUpdate: "crm_contact_update",
            CanView: "crm_contact_view",
        },
        Customer: {
            CanAccess: "crm_customer_access",
            CanAdd: "crm_customer_add",
            CanDelete: "crm_customer_delete",
            CanExport: "crm_customer_export",
            CanImport: "crm_customer_import",
            CanUpdate: "crm_customer_update",
            CanView: "crm_customer_view",
        },
        CustomerRelationshipManagment: {
            CanAccess: "crm_access",
        },
    }
    public DigitalSignageDashboard = {
        DigitalSignage: {
            CanAccess: "digital_signage_configuration_access",
            CanAccessReport: "digital_signage_configuration_report",
            CanUpdate: "digital_signage_configuration_update",
            CanView: "digital_signage_configuration_view",
        },
    }
    public Files = {
        Files: {
            CanAccess: "file_access",
            CanDelete: "file_delete",
            CanDeleteAny: "file_delete_any",
            CanDownload: "file_download",
            CanDownloadAny: "file_download_any",
            CanUpload: "file_upload",
        },
    }
    public HumanCapital = {
        Employee: {
            CanAccess: "humancapital_employee_access",
            CanAdd: "humancapital_employee_add",
            CanAssign: "humancapital_employee_assign_to_user",
            CanDelete: "humancapital_employee_delete",
            CanUpdate: "humancapital_employee_update",
            CanView: "humancapital_employee_view",
        },
        EmployeePosition: {
            CanAccess: "humancapital_employeeposition_access",
            CanAdd: "humancapital_employeeposition_add",
            CanDelete: "humancapital_employeeposition_delete",
            CanUpdate: "humancapital_employeeposition_update",
            CanView: "humancapital_employeeposition_view",
        },
        HumanCapital: {
            CanAccess: "humancapital_access",
        },
        IncidentManagment: {
            CanAccess: "humancapital_incident_access",
            CanAdd: "humancapital_incident_add",
            CanViewCross: "humancapital_incident_cross_view",
            CanDelete: "humancapital_incident_delete",
            CanUpdate: "humancapital_incident_update",
            CanView: "humancapital_incident_view",
        },
    }
    public Logistics = {
        default: {
            CanAccess: "carrier_access",
            CanAdd: "carrier_add",
            CanDelete: "carrier_delete",
            CanUpdate: "carrier_update",
            CanView: "carrier_view",
        },
        Shiipment: {
            CanAccess: "shipment_access",
            CanAdd: "shipment_add",
            CanDelete: "shipment_delete",
            CanPrint: "shipment_print_bol",
            CanUpdate: "shipment_update",
            CanView: "shipment_view",
        },
    }
    public Metrics = {
        Metrics: {
            CanView: "metrics_functional_view",
        },
    }
    public Operations = {
        CanAccess: "operations_access",
        Inventory: {
            CanAccess: "inventory_access",
            CanMove: "inventory_move",
            CanUpdate: "inventory_update",
            CanView: "inventory_view",
        },
        Materials: {
            CanAdd: "catalogs_materials_add",
            CanDelete: "catalogs_materials_delete",
            CanExport: "catalogs_materials_export",
            CanImport: "catalogs_materials_import",
            CanUpdate: "catalogs_materials_update",
            CanView: "catalogs_materials_view",
            CanAccess: "catalogs_material_access",
        },
        Project: {
            CanAccess: "projects_access",
            CanAdd: "project_add",
            CanDelete: "project_delete",
            CanExport: "project_export",
            CanUpdate: "project_update",
            CanUpdateProjectCustomer: "project_update_customer",
            CanView: "project_view",
        },
        Warehouse: {
            CanAccess: "inventory_warehouse_access",
            CanAdd: "inventory_warehouse_add",
            CanChange: "inventory_warehouse_configuration_change",
            CanDelete: "inventory_warehouse_delete",
            CanUpdate: "inventory_warehouse_update",
            CanView: "inventory_warehouse_view",
        },
        WorkOrder: {
            CanAccess: "workorder_access",
            CanAdd: "workorder_add",
            CanAttach: "workorder_attach_file",
            CanDelete: "workorder_delete",
            CanDownload: "workorder_download_file",
            CanExport: "workorder_export",
            CanUpdate: "workorder_update",
            CanView: "workorder_view",
        },
    }
    public Reports = {
        Reports: {
            CanAccess: "report_access",
            CanView: "report_list_view",
        },
    }
    public Security = {
        Group: {
            CanAccess: "security_group_access",
            CanAdd: "security_group_add",
            CanDelete: "security_group_delete",
            CanUpdateMembers: "security_group_members_update",
            CanUpdatPermissions: "security_group_permissions_update",
            CanUpdateGroup: "security_group_update",
            CanView: "security_group_view",
        },
        Security: {
            CanAccess: "security_access",
        },
        User: {
            CanAccess: "security_user_access",
            CanAdd: "security_user_add",
            CanDelete: "security_user_delete",
            CanChange: "security_user_password_change",
            CanUpdate: "security_user_profile_update",
            CanView: "security_user_view",
        },
    }
    public ServiceRequest = {
        ServiceRequest: {
            CanAccess: "service_request_access",
            CanAssign: "service_request_assign",
            CanCancel: "service_request_cancel",
            CanCreate: "service_request_create",
            CanUpdate: "service_request_update",
        },
        ServiceRequestDefinition: {
            CanAccess: "service_request_definition_access",
            CanAdd: "service_request_definition_add",
            CanDelete: "service_request_definition_delete",
            CanUpdate: "service_request_definition_update",
        },
        Workflow: {
            CanAccess: "service_request_workflow_access",
            CanAdd: "service_request_workflow_add",
            CanDelete: "service_request_workflow_delete",
            CanUpdate: "service_request_workflow_update",
        },
    }
    public System = {
        Attribute: {
            CanAdd: "attribute_add",
            CanDelete: "attribute_delete",
            CanDisable: "attribute_disable",
            CanEnable: "attribute_enable",
            CanUpdate: "attribute_update",
        },
        Configuration: {
            CanAccess: "system_configuration_access",
            CanUpdate: "system_configuration_update",
            CanView: "system_configuration_view",
        },
        Settings: {
            CanAccess: "system_settings_access",
            CanUpdate: "system_settings_update",
            CanView: "system_settings_view",
        },
        System: {
            CanAccessAttributes: "attribute_access",
            CanAccess: "system_access",
        },
        Templates: {
            CanAccess: "system_templates_aaccess",
            CanAdd: "system_templates_add",
            CanUpdate: "system_templates_update",
            CanView: "system_templates_view",
        },
    }
}
