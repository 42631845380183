import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_RESULT_SUCCESS, BasicApiResponse, CrudApiResponse, FilterEntry, GlobalConfiguration, PageablePayload, Session, SessionService } from '../../shared';
import { Group, IsAuthenticated, JWTToken, OAuth2Auhtorization, Permission, Role, User, UserPermissions } from '../security';

import { jwtDecode } from 'jwt-decode';
import { map } from 'rxjs/operators';
import { BaseDataServiceProvider } from '../dataservice.definition';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseDataServiceProvider<User> {

    constructor(public override config: GlobalConfiguration, public override http: HttpClient) {
        super(config, http, "User");
    }

    public getUserPermissions(): Observable<CrudApiResponse<UserPermissions>> {
        return this.http.get<CrudApiResponse<UserPermissions>>(this.config.getAPIUrl('/security/user/permissions'))
            .pipe((result) => {
                return result;
            });
    }
}

