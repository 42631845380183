import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'htmlDisplay',
    standalone: true, 
    pure: false
})
export class HtmlDisplayPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {
    }

    transform(content: string | string[]) {
        if (content) {
            if (content instanceof Array) {
                let ret = '';
                content.forEach((m) => {
                    ret = ret + m + '<br />';
                });
                return this.sanitizer.bypassSecurityTrustHtml(ret);
            } else {
                return this.sanitizer.bypassSecurityTrustHtml(content);
            }
        }
        return content
    }
}
