import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import { GlobalConfiguration } from "./global-configuration";

export interface Payload<T> {
   content: T;
   links: any;
}

export interface PageablePayload<T> {
  content: Array<T>;
  links: any;
  page_size: number;
  current_page: number;
  total_pages: number;
  total_records: number;
}

export interface BasicApiResponse {
    status: string;
    message: string;
}

export interface FilterEntry {
  property: string;
  value: string | null | number | boolean | any;
}

export class Filter implements FilterEntry {
  property: string;
  value: string;

  constructor(property: string, value: string) {
    this.property = property;
    this.value = value;
  }

  public toString (): string {
    return this.property + '|' + this.value;
  }
}

export interface BasicApiResponse {
  status: string;
  messages: string[];
}

export interface CrudApiResponse<T> extends BasicApiResponse {
  entity: T;
}

export interface DataUploadInformationStats {
  invalid_entries: boolean;
  success_records: number;
  total_records: number;
}

export interface DataUploadRowError {
  errors: string[];
  row: number;
}

export interface DataUploadApiResponse {
  information: DataUploadInformationStats;
  messages: DataUploadRowError[];
  status: string;  
}

export const API_RESULT_SUCCESS    = "success";
export const API_RESULT_ERROR      = "error";
export const API_RESULT_NOT_CALLED = "not_called";

export class PageablePayloadDefault<T> implements PageablePayload<T> {
    content!: Array<T>;
    page_size = environment.uiConfig.pageSize;
    total_pages = 0;
    total_records = 0;
    current_page = 1;
    links = [];

    constructor(intialData: Array<T> = []) {
        this.content = intialData;
        this.page_size = intialData.length;
        this.total_pages = 1;
        this.total_records = intialData.length;
        this.current_page = 1;
    }
}

export enum StateChangeType {
  UNDEFINED = -1,
  CANCELED = 0,
  ADDED = 1,
  UPDATED = 2,
  DELETED = 3,
  ADD_AND_REFRESH = 10,
  REFRESHED = 99
}

export class UIStateChanged {
  public changeType: StateChangeType = StateChangeType.UNDEFINED;
  public payload? : any = {};
}

export interface PageEvent {
    pageIndex: number;
    previousPageIndex?: number;
    pageSize: number;
    length: number;
}
