import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { PageEvent } from '../model.helpers';

@Injectable({
   providedIn: 'root'
})
export class PaginatorDefaults {  
    public updatePage(e: PageEvent, self: any) {
        self.refresh({
          page: {
            current: e.pageIndex + 1,
            size: e.pageSize
          }
        });
    }

    public refresh(self:any, current_page: number = 1) { 
        self.refresh({
            page: {
                current: current_page,
                size: environment.uiConfig.pageSize
              }
            });
    }
}
 