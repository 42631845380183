import { Optional } from '@angular/core';
import { environment } from '../../environments/environment';

export interface Configuration {
   host: String;
   port: Number;
   basePath: String;
   useSSL: Boolean;
   resourcePath: String;
}

export const FIND_ALL = "FIND_ALL";
export const ADD = "ADD";
export const GET_BY_ATTRIBUTE = "GET_BY_ATTRIBUTE";
export const UPDATE = "UPDATE";
export const REMOVE = "REMOVE";
export const SEARCH = "SEARCH";

const _api_configuration: any = {
    "Project": {
        FIND_ALL: "operation/projects",
        ADD: "operation/project",
        GET_BY_ATTRIBUTE: "operation/project/:code",
        UPDATE: "operation/project/:code",
        REMOVE: "operation/project/:code"
    },
    "Shipment": {
        FIND_ALL: "logistics/shipments",
        ADD: "logistics/shipment",
        GET_BY_ATTRIBUTE: "logistics/shipment/:code",
        UPDATE: "logistics/shipment/:code",
        REMOVE: "logistics/shipment/:code"
    },
    "Plant": {
        FIND_ALL: "operation/plants",
        ADD: "operation/plant",
        GET_BY_ATTRIBUTE: "operation/plant/:code",
        UPDATE: "operation/plant/:code",
        REMOVE: "operation/plant/:code",
    },
    "Material": {
        FIND_ALL: "operation/materials",
        ADD: "operation/material",
        GET_BY_ATTRIBUTE: "operation/material/:code",
        UPDATE: "operation/material/:code",
        REMOVE: "operation/material/:code",
    },
    "MaterialCategory": {
        FIND_ALL: "operation/materialcategories",
        ADD: "operation/materialcategory",
        GET_BY_ATTRIBUTE: "operation/materialcategory/:code",
        UPDATE: "operation/materialcategory/:code",
        REMOVE: "operation/materialcategory/:code",
    },
    "WorkorderMaterial": {
        SEARCH: "operation/workorder/:workorderCode/materials/search"
    },
    "Carrier": {
        FIND_ALL: "logistics/carriers",
        ADD: "logistics/carrier",
        GET_BY_ATTRIBUTE: "logistics/carrier/:code",
        UPDATE: "logistics/carrier/:code",
        REMOVE: "logistics/carrier/:code",
    },
    "Depot": {
        FIND_ALL: "assetmanagment/depots",
        ADD: "assetmanagment/depot",
        GET_BY_ATTRIBUTE: "assetmanagment/depot/:code",
        UPDATE: "assetmanagment/depot/:code",
        REMOVE: "assetmanagment/depot/:code",
    },
    "Asset": {
        FIND_ALL: "assetmanagment/assets",
        ADD: "assetmanagment/asset",
        GET_BY_ATTRIBUTE: "assetmanagment/asset/:code",
        UPDATE: "assetmanagment/asset/:code",
        REMOVE: "assetmanagment/asset/:code",
    },
    "AssetCategory": {
        FIND_ALL: "assetmanagment/asset_category",
        ADD: "assetmanagment/asset_category",
        GET_BY_ATTRIBUTE: "assetmanagment/asset_category/:id",
        UPDATE: "assetmanagment/asset_category/:id",
        REMOVE: "assetmanagment/asset_category/:id",
    },
    "Supplier": {
        FIND_ALL: "assetmanagment/suppliers",
        ADD: "assetmanagment/supplier",
        GET_BY_ATTRIBUTE: "assetmanagment/supplier/:id",
        UPDATE: "assetmanagment/supplier/:id",
        REMOVE: "assetmanagment/supplier/:id",
    },
    "Incident": {
        FIND_ALL: "humancapital/healthandsafety/incidents",
        ADD: "humancapital/healthandsafety/incident",
        GET_BY_ATTRIBUTE: "humancapital/healthandsafety/incident/:code",
        UPDATE: "humancapital/healthandsafety/incident/:code",
        REMOVE: "humancapital/healthandsafety/incident/:code",
    },
    "Employee": {
        FIND_ALL: "humancapital/employees",
        ADD: "humancapital/employee",
        GET_BY_ATTRIBUTE: "humancapital/employee/:id",
        UPDATE: "humancapital/employee/:id",
        REMOVE: "humancapital/employee/:id",
    },
    "EmployeePosition": {
        FIND_ALL: "humancapital/employeepositions",
        ADD: "humancapital/employeeposition",
        GET_BY_ATTRIBUTE: "humancapital/employeeposition/:id",
        UPDATE: "humancapital/employeeposition/:id",
        REMOVE: "humancapital/employeeposition/:id",
    },
    "Customer": {
        FIND_ALL: "crm/customers",
        ADD: "crm/customer",
        GET_BY_ATTRIBUTE: "crm/customer/:id",
        UPDATE: "crm/customer/:id",
        REMOVE: "crm/customer/:id",
    },
    "User": {
        FIND_ALL: "security/users",
        ADD: "security/user",
        GET_BY_ATTRIBUTE: "security/user/:username",
        UPDATE: "security/user/:username",
        REMOVE: "security/user/:username",
    },    
    "Group": {
        FIND_ALL: "security/groups",
        ADD: "security/group",
        GET_BY_ATTRIBUTE: "security/group/:id",
        UPDATE: "security/group/:id",
        REMOVE: "security/group/:id",
    },    
}

function __getApiUri<T>(typeName: string, method: string ) {
    return _api_configuration[typeName][method];
}

export class GlobalConfiguration {

   private currentConfig: Configuration = {
      host: environment.apiConfig.host,
      port: environment.apiConfig.port,
      basePath: environment.apiConfig.basePath,
      useSSL: environment.apiConfig.useSSL,
      resourcePath: environment.apiConfig.resourcePath
   };

   public constructor( @Optional() config: Configuration ) {
      this.currentConfig.host = config.host;
      this.currentConfig.port = config.port;
      this.currentConfig.basePath = config.basePath;
      this.currentConfig.useSSL = config.useSSL;
      this.currentConfig.resourcePath = config.resourcePath;
   }

   public getAPIUrlForType<T>(typeName: string, apiMethod: string): string {
    return ( this.currentConfig.useSSL ? 'https://' : 'http://' ) + this.currentConfig.host + ':' 
                                                                  + this.currentConfig.port 
                                                                  + this.currentConfig.basePath 
                                                                  + '/'
                                                                  + __getApiUri<T>(typeName, apiMethod);
   }

   public getAPIUrl(apiPath: string) {
      return ( this.currentConfig.useSSL ? 'https://' : 'http://' )
         + this.currentConfig.host + ':' + this.currentConfig.port + this.currentConfig.basePath + (apiPath.startsWith('/') ? apiPath : '/' + apiPath);
   }
   public getImageResourcesPath() {
         return this.currentConfig.resourcePath + '/images';
   }

}